import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import { fetchCurrentBhome, fetchTechnicianActivities, setCurrentBhome } from 'actions/app.actions';
import { getBhomes } from '../selectors/bhome.ids.selector';
import jigsMap from './jigsMap';

const useBhomeAutoPickerHook = () => {
    const dispatch = useDispatch();
    const areBhomesFetched = useSelector(state => state.app.areBhomesFetched);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const user = useSelector(state => state.app.user, shallowEqual);
    const isEMTester = usePermission('isEMTester', 'technician');
    const isGripperTester = usePermission('isGripperTester', 'technician');
    const isFLTester = usePermission('isFLTester', 'technician');
    const isStationTester = usePermission('isStationTester', 'technician');

    useEffect(() => {
        if (isEMTester || isGripperTester || isFLTester || isStationTester) {
            if (areBhomesFetched && bhomes?.length && user?.id) {
                const jigId = jigsMap[user?.email];
                if (jigId) {
                    dispatch(fetchCurrentBhome(jigId));
                    dispatch(setCurrentBhome(jigId));
                    dispatch(fetchTechnicianActivities(jigId));
                }
            }
        }
    }, [
        bhomes?.length,
        areBhomesFetched,
        user?.id,
        user?.email,
        dispatch,
        isEMTester,
        isGripperTester,
        isFLTester,
        isStationTester,
    ]);
};

export default useBhomeAutoPickerHook;
