import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import Card from 'components/reusables/Card';
import readJsonFile from 'utils/readJsonFile';
import { fetchInjectBhomeData } from '../../actions';

const DataInjection = ({ bhome }) => {
    const dispatch = useDispatch();
    const fileRef = useRef(null);
    const [fileValue, setFileValue] = useState(null);
    const [, setFileError] = useState('');

    const handleFileUpload = async e => {
        if (e?.target?.files?.[0]) {
            try {
                const result = await readJsonFile(e?.target?.files?.[0]);
                setFileValue(result);
            } catch (e) {
                setFileError(e);
            }
        }
    };

    const handleInjectDataClick = () => {
        dispatch(
            fetchInjectBhomeData({
                bhomeId: bhome.id,
                data: fileValue,
                resolver: () => {
                    if (fileRef?.current?.value) {
                        fileRef.current.value = null;
                    }
                    setFileValue(null);
                    setFileError('');
                },
            })
        );
    };

    return (
        <div className="qa-data-injector">
            <Card className="qa-data-injector-card" title="Data Injection">
                {!bhome?.id && <div>Please select bhome to continue</div>}
                {bhome?.id && (
                    <>
                        <input
                            title="Pick valid JSON file"
                            name="file"
                            type="file"
                            accept=".json"
                            onChange={handleFileUpload}
                            ref={fileRef}
                        />
                        <Button className="btn-primary" disabled={!fileValue} onClick={handleInjectDataClick}>
                            Inject Data
                        </Button>
                    </>
                )}
            </Card>
        </div>
    );
};

DataInjection.propTypes = {
    bhome: PropTypes.shape(),
};

export default DataInjection;
