import React from 'react';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import Card from 'components/reusables/Card';
import { fetchRecreateDailyBeekeeperDashboardData } from '../../actions';

const DataCreator = () => {
    const dispatch = useDispatch();

    const recreateDailyBeekeeperDashboardDataClick = () => {
        dispatch(fetchRecreateDailyBeekeeperDashboardData());
    };

    return (
        <div className="qa-data-creator">
            <Card className="qa-data-creator-card" title="Data Creation">
                <Button className="btn-primary" onClick={recreateDailyBeekeeperDashboardDataClick}>
                    Recreate Daily Beekeeper Dashboard Data (Workspace)
                </Button>
            </Card>
        </div>
    );
};

export default DataCreator;
