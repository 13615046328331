export const getDaysOptions = status =>
    status?.days_of_week
        ? Object.keys(status?.days_of_week)?.map(key => ({
              label: key,
              value: key,
          }))
        : [];

export const getDaysValue = status =>
    status?.days_of_week ? Object.keys(status?.days_of_week)?.filter(item => status?.days_of_week?.[item]) : [];

export const AUTO_SCAN_TYPES = [
    { value: 'beeCount only', label: 'Bee Count Only' },
    { value: 'full', label: 'Full' },
];

export const PREFIX_OPTIONS = [
    {
        label: 'Auto Frame Layout',
        value: 'DAILY FRAME LAYOUT VALIDATION BHOME',
    },
    {
        label: 'Auto Feed',
        value: 'AUTO FEED VALIDATION BHOME',
    },
    {
        label: 'Auto Thermal Scan',
        value: 'THERMAL SCAN VALIDATION BHOME',
    },
    {
        label: 'Auto Scan/Bee Count',
        value: 'AUTO SCAN VALIDATION BHOME',
    },
    {
        label: 'Auto Heat Treatment',
        value: 'AUTO HEAT TREATMENT VALIDATION BHOME',
    },
];
