import React from 'react';
import { loading } from '@beewise/react-utils';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentBhome } from 'selectors';
import { FETCH_RECREATE_DAILY_BEEKEEPER_DASHBOARD_DATA, FETCH_INJECT_BHOME_DATA } from './actionTypes';
import DataCreator from './components/DataCreator';
import DataInjection from './components/DataInjection';

const Qa = () => {
    const bhome = useSelector(getCurrentBhome, shallowEqual);

    return (
        <div className="qa-view">
            <div className="qa-view-content">
                <DataCreator />
                <DataInjection bhome={bhome} />
            </div>
        </div>
    );
};

export default loading([FETCH_RECREATE_DAILY_BEEKEEPER_DASHBOARD_DATA.default, FETCH_INJECT_BHOME_DATA.default])(Qa);
