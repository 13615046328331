import {
    FETCH_ALL_BHOMES_MESSAGES,
    TOGGLE_KPI_VIEW,
    MARK_MESSAGES_AS_FAILED,
    FETCH_GET_MESSAGE,
    FETCH_CANCEL_MESSAGE,
} from '../actionTypes';

export const fetchAllBhomesMessages = (from, to, command) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES_MESSAGES.default,
        url: `/messages?from=${from}&to=${to}${command ? `&command=${command}` : ''}`,
    },
});

export const fetchMessageById = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_MESSAGE.default,
        url: `/messages/message/${id}`,
        resolver,
    },
});

export const toggleKpiView = () => ({
    type: TOGGLE_KPI_VIEW,
});

export const markMessagesAsFailed = (bhomeId, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: MARK_MESSAGES_AS_FAILED.default,
        url: `/messages/bhomes/${bhomeId}/mark-failed`,
        resolver,
    },
});

export const fetchCancelMessage = (id, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_CANCEL_MESSAGE.default,
        url: `/messages/v4/${id}/cancel`,
        resolver,
    },
});
