import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { faCancel } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const STATUSES_TO_CANCEL = [
    constants.MESSAGE_STATUS.PENDING,
    // TODO: uncomment once MW part is ready
    // constants.MESSAGE_STATUS.SENT,
    // constants.MESSAGE_STATUS.ACKNOWLEDGED,
];

const StatusRenderer = ({ data, colDef }) => {
    const handleCancelClick = () => colDef?.rendererParams?.onCancelClick(data?.id);

    const shouldDisplayIcon =
        STATUSES_TO_CANCEL.includes(data?.status) && data.command !== constants.COMMANDS.CANCEL_SEQUENCES;

    return (
        <div className="messages-status-renderer">
            {data.status}
            {shouldDisplayIcon && (
                <FontAwesomeIcon
                    className="messages-status-renderer-icon"
                    icon={faCancel}
                    onClick={handleCancelClick}
                />
            )}
        </div>
    );
};

StatusRenderer.propTypes = {
    data: PropTypes.shape(),
    colDef: PropTypes.shape(),
};

export default StatusRenderer;
