import React, { useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { get, noop } from 'lodash-es';
import constants from 'appConstants';
import { arrayOfObjectsShallowEqual, usePermission, useToggle } from '@beewise/react-utils';
import Button from '@beewise/button';
import { SelectField } from '@beewise/select-field';
import isSignInViewOrUpView from 'utils/isSignInOrUpView';
import { UI_ENV } from 'config';
import {
    fetchAllBhomes,
    setCurrentBhome,
    fetchChangeBhomeStatus,
    fetchTechnicianActivities,
    fetchCurrentBhome,
} from 'actions/app.actions';
import StopButton from 'components/reusables/StopButton';
import PendingTasks from 'components/reusables/PendingTasks';
import { getCurrentBhome, getIsCurrentBhomeOnline } from 'selectors';
import { BeehomeStatus } from '@beewise/beehome-status';
import { solarDataKeysMap } from 'components/views/PowerManagement/utils/getGridOptions';
import { getSortedBhomeIdsOptions } from './selectors/bhome.ids.selector';
import { ProfileModal } from './components';
import Activities from './components/Activities';
import Ssh from './components/Ssh';
import BhomeOnline from './components/BhomeOnline';
import statusOptions from './utils/getStatusOptions';
import logo from './beewise-logo.svg';
import { fetchIsBhomeOnline } from '../SideMenu/actions';
import useBhomeAutoPickerHook from './hooks/bhomeAutoPickerHook';

import './AppHeader.scss';

const AppHeader = () => {
    const [isProfileModalOpen, toggleProfileModal] = useToggle(false);
    const [isActivitiesShown, toggleActivitiesShown] = useToggle(false);
    const isLoggedIn = useSelector(state => state.app.isLoggedIn);
    const location = useLocation();
    const dispatch = useDispatch();
    const options = useSelector(getSortedBhomeIdsOptions, arrayOfObjectsShallowEqual);
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const isCurrentBhomeOnline = useSelector(getIsCurrentBhomeOnline);
    const areBhomesFetched = useSelector(state => state.app.areBhomesFetched);
    const isManufacturer = usePermission('isManufacturer', 'technician');
    const isEMTester = usePermission('isEMTester', 'technician');
    const isGripperTester = usePermission('isGripperTester', 'technician');
    const isFLTester = usePermission('isFLTester', 'technician');
    const isStationTester = usePermission('isStationTester', 'technician');

    const isSignInView = useMemo(() => isSignInViewOrUpView(location), [location]);

    const isConnectivityMapView = useMemo(() => location.pathname.includes('monitoring'), [location]);

    useEffect(() => {
        if (!isSignInView && !isConnectivityMapView && !areBhomesFetched && isLoggedIn) {
            dispatch(fetchAllBhomes(true));
        }
    }, [areBhomesFetched, dispatch, isConnectivityMapView, isLoggedIn, isSignInView]);

    useBhomeAutoPickerHook();

    const handleBhomeChange = useCallback(
        value => {
            if (isEMTester || isFLTester || isGripperTester) {
                return;
            }
            if (value) {
                dispatch(fetchCurrentBhome(value));
                dispatch(setCurrentBhome(value));
                dispatch(fetchTechnicianActivities(value));
            }
        },
        [dispatch, isEMTester, isFLTester, isGripperTester]
    );

    const handleBhomeStatusChange = useCallback(
        value => {
            if (value === constants.BHOME_STATUSES.INITIALIZING || value === constants.BHOME_STATUSES.ERROR) {
                return;
            }
            if (isManufacturer && value === constants.BHOME_STATUSES.RECEPTIVE) {
                return;
            }
            dispatch(fetchChangeBhomeStatus(bhome?.id, value));
        },
        [bhome?.id, dispatch, isManufacturer]
    );

    const clearCurrentBhome = useCallback(() => {
        dispatch(setCurrentBhome(null));
    }, [dispatch]);

    return (
        <header className="app-header">
            <div className="logo-container">
                <NavLink to="/">
                    <img className="logo" src={logo} alt="Beewise logo" />
                </NavLink>
                <div className={`app-env ${UI_ENV}`}>{UI_ENV}</div>
            </div>
            {!isSignInView && (
                <>
                    <div className="main-menu">
                        {!!bhome && (
                            <>
                                <Ssh bhomeId={bhome.id} />
                                <PendingTasks bhome={bhome} />
                                <StopButton bhome={bhome} />
                                <BhomeOnline isOnline={isCurrentBhomeOnline} />
                                <SelectField
                                    placeholder="Status"
                                    options={statusOptions}
                                    value={get(bhome, 'status', null)}
                                    onChange={handleBhomeStatusChange}
                                    className="beewise-select"
                                    size="small"
                                />
                            </>
                        )}
                        <SelectField
                            disabled={
                                !areBhomesFetched || isEMTester || isFLTester || isGripperTester || isStationTester
                            }
                            placeholder="Select beehome"
                            options={options}
                            value={get(bhome, 'id', null)}
                            search
                            onChange={handleBhomeChange}
                            className="beewise-select beehome-select"
                            size="small"
                        />
                        <Button
                            iconType="x"
                            className={cx('bhome-remover', {
                                'bhome-remover-hidden':
                                    !bhome || isEMTester || isFLTester || isGripperTester || isStationTester,
                            })}
                            disabled={isEMTester || isFLTester || isGripperTester || isStationTester}
                            onClick={clearCurrentBhome}
                        />
                        <Button
                            className="icon activity"
                            iconType="activity"
                            onClick={toggleActivitiesShown}
                            disabled={!bhome}
                        />
                        {!!bhome && (
                            <BeehomeStatus
                                voltage={get(bhome, 'solar_data.adc_vbterm', null)}
                                bhome={bhome}
                                fetchIsBhomeOnline={fetchIsBhomeOnline}
                                onChangeOnlineStatus={noop}
                                solarDataTooltipProps={{
                                    position: 'bottom right',
                                    offset: [14, 0, 0, 0],
                                }}
                                solarKeysMap={solarDataKeysMap}
                            />
                        )}
                        <Button
                            className="icon"
                            iconType="user"
                            iconClassName="user-white"
                            onClick={toggleProfileModal}
                        />
                    </div>
                    <ProfileModal isOpen={isProfileModalOpen} toggleModal={toggleProfileModal} />
                    {isActivitiesShown && <Activities onClose={toggleActivitiesShown} />}
                </>
            )}
        </header>
    );
};

export default AppHeader;
