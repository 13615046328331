import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { UI_ENV } from 'config';
import { get } from 'lodash-es';
import { SelectField } from '@beewise/select-field';
import constants from 'appConstants';
import Card from 'components/reusables/Card';
import analytics from 'utils/analytics';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { HIVES } from 'utils';
import { fetchRebootDevice, fetchFrames } from '../../actions';
import { slotsOptions } from '../../utils';
import FeederPumpControl from './components/FeederPumpControl';
import IrTemperature from './components/IrTemperature';
import ModeControl from './components/ModeControl';

const UNKNOWN_VALUE = 'Unknown';

const GeneralFunctions = ({
    bhome,
    robotStatus,
    systemStatuses,
    handleTechnicianActionClick,
    isQc,
    isFLTester,
    isNavCalibrationInProgress,
}) => {
    const [pickedHives, setPickedHives] = useState([]);
    const [pickedSlot, setPickedSlot] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFrames(bhome.id));
    }, [bhome.id, dispatch]);

    const handleInitRobotParamsClick = useCallback(() => {
        analytics.fireGeneralRobotEvent({
            action: constants.COMMANDS.INIT_ROBOT_PARAMS,
            label: `Beehome ${bhome.id}`,
        });

        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.INIT_ROBOT_PARAMS,
            })
        );
    }, [dispatch, bhome]);

    const handleReboot = useCallback(
        type => () => {
            if (
                // eslint-disable-next-line no-alert
                window.confirm('Are you sure you want to reboot current device?')
            ) {
                dispatch(fetchRebootDevice(bhome.id, type));
            }
        },
        [dispatch, bhome.id]
    );

    const handleHiveChange = useCallback(
        value => {
            setPickedSlot(null);
            setPickedHives(value);
        },
        [setPickedHives]
    );

    const handleSlotMapClick = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(
                bhome.id,
                {
                    type: constants.COMMANDS.FRAME_LAYOUT_BUNDLE,
                    params: {
                        sequences: [
                            {
                                command: constants.COMMANDS.INITIALIZE_ROBOT,
                                params: {},
                            },
                            {
                                command: constants.COMMANDS.UPDATE_FRAME_LAYOUT,
                                params: {},
                            },
                        ],
                    },
                },
                () => {
                    dispatch(
                        fetchSendTechnicianAction(bhome.id, {
                            type: constants.COMMANDS.RESUME_PENDING_TASKS,
                        })
                    );
                }
            )
        );
    }, [bhome.id, dispatch]);

    const handleSendChangeEnvironment = useCallback(() => {
        // eslint-disable-next-line no-alert
        if (window.confirm("Are you sure you want to change the environment? This action can't be undone")) {
            dispatch(
                fetchSendTechnicianAction(bhome.id, {
                    type: constants.COMMANDS.CHANGE_ENVIRONMENT,
                    params: {
                        environment: 'prod',
                        region: 'eu-west-1',
                    },
                })
            );
        }
    }, [bhome.id, dispatch]);

    const handleThermalScan = useCallback(
        () =>
            dispatch(
                fetchSendTechnicianAction(bhome.id, {
                    type: constants.COMMANDS.THERMAL_SCAN_BUNDLE,
                    params: {
                        sequences: [
                            {
                                command: constants.COMMANDS.INITIALIZE_ROBOT,
                                params: {},
                            },
                            {
                                command: constants.COMMANDS.THERMAL_SCAN_FULL,
                                params: {},
                            },
                        ],
                    },
                })
            ),
        [bhome.id, dispatch]
    );

    return (
        <div className="general-functions">
            <Card className="initialize" title="General Functions">
                {!isFLTester && (
                    <div className="wrapped-line">
                        {systemStatuses?.cameras && (
                            <div className="camera-status">
                                <div className="camera-status-header">Available cameras:</div>
                                {systemStatuses.cameras.map(camera => (
                                    <div className="camera-status-item" key={camera.device}>
                                        {camera.model} - {camera.device}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex-line">
                            <TechButton
                                className="btn-primary"
                                onClick={handleTechnicianActionClick(constants.COMMANDS.INITIALIZE_ROBOT)}
                                showToManufacturer
                            >
                                Initialize
                            </TechButton>
                            <TechButton
                                className="btn-primary"
                                onClick={handleTechnicianActionClick(constants.COMMANDS.RESET_ROBOT)}
                                showToManufacturer
                            >
                                Reset
                            </TechButton>
                            <TechButton
                                className="btn-primary"
                                onClick={handleTechnicianActionClick(constants.COMMANDS.WHAT_ARE_YOU_DOING)}
                                showToManufacturer
                                enabledInNonError
                            >
                                WAYD
                            </TechButton>
                            <TechButton
                                className="btn-primary"
                                onClick={handleTechnicianActionClick(constants.COMMANDS.EXIT_STANDBY)}
                                enabledInNonError
                            >
                                Exit StandBy
                            </TechButton>
                            {isQc && UI_ENV === 'stage' && (
                                <TechButton
                                    className="btn-primary"
                                    onClick={handleSendChangeEnvironment}
                                    showToManufacturer
                                    enabledInReceptiveAndMaintenance
                                >
                                    Change Environment
                                </TechButton>
                            )}
                        </div>
                        <div className="flex-line">
                            <TechButton className="btn-primary" onClick={handleInitRobotParamsClick} showToManufacturer>
                                Init Robot Params
                            </TechButton>
                        </div>
                        <div className="flex-line no-left-margin">
                            <div className="hide-for-manufacturer">
                                <TechButton
                                    showToManufacturer
                                    enabledInReceptiveAndMaintenance
                                    onClick={handleSlotMapClick}
                                >
                                    Create Layout
                                </TechButton>
                            </div>
                            <FeederPumpControl bhome={bhome} />
                            <ModeControl bhome={bhome} />
                        </div>
                        <div className="flex-line no-left-margin hide-for-manufacturer">
                            <SelectField
                                value={pickedHives}
                                options={HIVES}
                                onChange={handleHiveChange}
                                placeholder="Choose hive"
                                isMulti
                                size="small"
                            />
                            <SelectField
                                value={pickedSlot}
                                options={slotsOptions()}
                                onChange={setPickedSlot}
                                placeholder="Choose slot"
                                disabled={!pickedHives.length || pickedHives.length > 1}
                                className="left-margin"
                                size="small"
                            />
                        </div>
                        <div className="flex-line no-left-margin hide-for-manufacturer">
                            Status:{' '}
                            <span className="strong">{get(robotStatus, 'state', UNKNOWN_VALUE).toLowerCase()}</span>
                        </div>
                        <div className="flex-line">
                            <TechButton
                                className="btn-primary"
                                onClick={handleReboot('full_mw_reboot')}
                                showToManufacturer
                            >
                                Reboot device
                            </TechButton>
                            <IrTemperature bhome={bhome} isNavCalibrationInProgress={isNavCalibrationInProgress} />
                        </div>
                        <div className="flex-line">
                            <TechButton className="btn-primary" onClick={handleThermalScan}>
                                Thermal scan
                            </TechButton>
                        </div>
                    </div>
                )}
                {isFLTester && (
                    <div className="fl-tester-ir-sensor">
                        <TechButton
                            className="btn-primary"
                            onClick={handleTechnicianActionClick(constants.COMMANDS.INITIALIZE_ROBOT)}
                            showToManufacturer
                        >
                            Initialize
                        </TechButton>
                        <IrTemperature bhome={bhome} isNavCalibrationInProgress={isNavCalibrationInProgress} />
                    </div>
                )}
            </Card>
        </div>
    );
};

GeneralFunctions.propTypes = {
    bhome: PropTypes.shape(),
    robotStatus: PropTypes.shape(),
    isNavCalibrationInProgress: PropTypes.bool,
    isQc: PropTypes.bool,
    isFLTester: PropTypes.bool,
    systemStatuses: PropTypes.shape(),
    handleTechnicianActionClick: PropTypes.func,
};

export default GeneralFunctions;
