import { capitalize } from 'lodash-es';

export const groupTooltipData = ({ api }) => {
    const dataArray = [];
    api?.forEachNode(({ data }) => dataArray.push(data));
    if (!dataArray.length) {
        return;
    }
    const groupedMessages = Object.groupBy(dataArray, ({ status }) => status);
    Object.entries(groupedMessages).forEach(([key, value]) => {
        groupedMessages[key] = value.length;
    });
    return { total: dataArray.length, ...groupedMessages };
};

export const getTooltip = tooltipContent =>
    Object.entries(tooltipContent)
        .map(
            ([status, value], i) =>
                `<div>
                    <p>
                        <span>${capitalize(status)}</span>: ${value} (${(
                    (value / tooltipContent.total) *
                    100
                ).toFixed()}%) 
                    </p> 
                    ${!i ? '<div class="divider" />' : ''}
                </div>`
        )
        .join('');
