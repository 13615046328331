import { FETCH_INJECT_BHOME_DATA, FETCH_RECREATE_DAILY_BEEKEEPER_DASHBOARD_DATA } from '../actionTypes';

export const fetchRecreateDailyBeekeeperDashboardData = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_RECREATE_DAILY_BEEKEEPER_DASHBOARD_DATA.default,
        method: 'POST',
        url: '/bhomes/v4/dashboard/recollect-daily-data',
        toastText: 'Command sent. It may take up to 10 minutes to see the changes.',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchInjectBhomeData = ({ bhomeId, data, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_INJECT_BHOME_DATA.default,
        method: 'POST',
        url: `/bhomes/v4/${bhomeId}/qa/inject`,
        body: data,
        toastText: 'Command sent.',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});
