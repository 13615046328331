import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import Button from '@beewise/button';
import { getActionsOptionsForMonitoring } from 'components/views/ActionsAndMonitoring/utils';
import { fetchBulkAction } from '../actions';

const BulkSActions = ({ selectedBhomeIds, setSelectedBhomeIds }) => {
    const dispatch = useDispatch();
    const [action, setAction] = useState(null);

    const handleActionsChange = useCallback(
        value => {
            setAction(value);
        },
        [setAction]
    );

    const handleChooseAction = useCallback(() => {
        dispatch(
            fetchBulkAction(selectedBhomeIds, action, () => {
                setSelectedBhomeIds([]);
                setAction(null);
            })
        );
    }, [action, dispatch, selectedBhomeIds, setSelectedBhomeIds]);

    const actionsOptions = getActionsOptionsForMonitoring();

    return (
        <>
            <SelectField
                value={action}
                options={actionsOptions}
                onChange={handleActionsChange}
                placeholder="Choose action"
                className="beehome-select"
                size="small"
            />
            <Button className="btn-primary" onClick={handleChooseAction} disabled={!selectedBhomeIds.length || !action}>
                Run
            </Button>
        </>
    );
};

BulkSActions.propTypes = {
    selectedBhomeIds: PropTypes.arrayOf(PropTypes.number),
    setSelectedBhomeIds: PropTypes.func,
};

export default BulkSActions;
