export const getItems = ({ isManufacturer, isQa }) => {
    if (isManufacturer) {
        return [
            { name: 'Actions & Status', link: '/' },
            { name: 'Messages', link: '/messages' },
            { name: 'Errors', link: '/errors' },
            { name: 'Power Management', link: '/power-management' },
            { name: 'Diagnostic Tab', link: '/diagnostic-tab' },
            { name: 'Monitoring', link: '/monitoring' },
            { name: 'Cameras Calibrations', link: '/cameras-calibrations' },
        ];
    }
    return [
        { name: 'Actions & Status', link: '/' },
        { name: 'Messages', link: '/messages' },
        { name: 'Errors', link: '/errors' },
        { name: 'Config', link: '/config' },
        { name: 'Frames', link: '/frames' },
        { name: 'Locked Hives', link: '/hives' },
        { name: 'Power Management', link: '/power-management' },
        { name: 'Cameras Calibrations', link: '/cameras-calibrations' },
        { name: 'Rfid History', link: '/rfid-history' },
        { name: 'Statistics', link: '/statistics' },
        { name: 'Monitoring', link: '/monitoring' },
        { name: 'Special Features', link: '/special-features' },
        { name: 'Diagnostic Tab', link: '/diagnostic-tab' },
        { name: 'Layout Actions', link: '/layout' },
        { name: 'Sw update monitor', link: '/sw-update-monitor' },
        ...(isQa ? [{ name: 'QA', link: '/qa' }] : []),
    ];
};
