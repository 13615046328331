import { SET_CURRENT_BHOME } from 'actionTypes';
import {
    FETCH_ALL_BHOME_ERRORS,
    FETCH_LATEST_BHOME_ERROR,
    CLEAR_LATEST_BHOME_ERROR,
    FETCH_UPDATE_BHOME_ERROR,
    FETCH_LATEST_BHOME_ALERT,
    CLEAR_LATEST_BHOME_ALERT,
} from '../actionTypes';

const initialState = {
    errors: [],
    latestBhomeError: null,
    latestBhomeAlert: null,
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BHOME_ERRORS.success:
            return {
                ...state,
                errors: action.data.errors,
            };
        case FETCH_LATEST_BHOME_ERROR.success:
            return {
                ...state,
                latestBhomeError: action.data.error,
            };
        case CLEAR_LATEST_BHOME_ERROR:
            return {
                ...state,
                latestBhomeError: null,
            };
        case FETCH_LATEST_BHOME_ALERT.success:
            return {
                ...state,
                latestBhomeAlert: action.data,
            };
        case CLEAR_LATEST_BHOME_ALERT:
            return {
                ...state,
                latestBhomeAlert: null,
            };
        case FETCH_UPDATE_BHOME_ERROR.success:
            return {
                ...state,
                errors: state.errors.map(error => {
                    if (error.id === action.data.error.id) {
                        return action.data.error;
                    }
                    return error;
                }),
            };
        case SET_CURRENT_BHOME:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};

export default dashboard;
