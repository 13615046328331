export default {
    'emtest01@beewise.ag': 1000,
    'emtest02@beewise.ag': 1001,
    'emtest03@beewise.ag': 1002,
    'emtest04@beewise.ag': 1003,
    'emtest05@beewise.ag': 1004,
    'emtest06@beewise.ag': 1005,
    'emtest07@beewise.ag': 1006,
    'emtest08@beewise.ag': 1007,
    'emtest09@beewise.ag': 1008,
    'emtest10@beewise.ag': 1009,
    'grippertest01@beewise.ag': 1010,
    'grippertest02@beewise.ag': 1011,
    'grippertest03@beewise.ag': 1012,
    'grippertest04@beewise.ag': 1013,
    'grippertest05@beewise.ag': 1014,
    'grippertest06@beewise.ag': 1015,
    'grippertest07@beewise.ag': 1016,
    'grippertest08@beewise.ag': 1017,
    'grippertest09@beewise.ag': 1018,
    'grippertest10@beewise.ag': 1019,
    'fltest01@beewise.ag': 1020,
    'fltest02@beewise.ag': 1021,
    'fltest03@beewise.ag': 1022,
    'fltest04@beewise.ag': 1023,
    'fltest05@beewise.ag': 1024,
    'fltest06@beewise.ag': 1025,
    'fltest07@beewise.ag': 1026,
    'fltest08@beewise.ag': 1027,
    'fltest09@beewise.ag': 1028,
    'fltest10@beewise.ag': 1029,
    'ectest01@beewise.ag': 1030,
    'ectest02@beewise.ag': 1031,
    'ectest03@beewise.ag': 1032,
    'ectest04@beewise.ag': 1033,
    'ectest05@beewise.ag': 1034,
    'ectest06@beewise.ag': 1035,
    'ectest07@beewise.ag': 1036,
    'ectest08@beewise.ag': 1037,
    'ectest09@beewise.ag': 1038,
    'ectest10@beewise.ag': 1039,
    'sttest01@beewise.ag': 1030,
    'sttest02@beewise.ag': 1031,
    'sttest03@beewise.ag': 1032,
};
