import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';
import { SelectField } from '@beewise/select-field';
import { untuckOptions } from 'components/views/ActionsAndMonitoring/utils';

const ExtractFrameParams = ({ params, setParams }) => {
    const toggleProp = useCallback(
        prop => () => {
            setParams(params => ({
                ...params,
                [prop]: !params?.[prop],
            }));
        },
        [setParams]
    );

    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                untuck_direction: value,
            }));
        },
        [setParams]
    );

    return (
        <>
            <Checkbox label="Shake" checked={params?.shake ?? false} onChange={toggleProp('shake')} />
            <Checkbox label="Extra Shake" checked={params?.extra_shake ?? false} onChange={toggleProp('extra_shake')} />
            <Checkbox
                label="Extra Spread"
                checked={params?.extra_spread ?? false}
                onChange={toggleProp('extra_spread')}
            />
            <Checkbox label="Count Bees" checked={params?.count_bees ?? false} onChange={toggleProp('count_bees')} />
            <SelectField
                value={params?.untuck_direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
        </>
    );
};

ExtractFrameParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default ExtractFrameParams;
