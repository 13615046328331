import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppHeader from 'components/reusables/AppHeader';
import { AnalProvider, PermissionsProvider, usePermission } from '@beewise/react-utils';
import { UI_ENV, TRACKING_CODE } from 'config';
import SideMenu from 'components/reusables/SideMenu';
import CurrentUsers from './components/reusables/CurrentUsers';
import { getCurrentBhome } from './selectors';
import Pages from './pages';

const Routes = () => {
    const [isMenuShown, setMenuShown] = useState(true);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const isManufacturer = usePermission('isManufacturer', 'technician');
    const isQa = usePermission('isQa', 'technician');

    return (
        <Router>
            <PermissionsProvider app="technician">
                <AnalProvider trackingCode={TRACKING_CODE} UI_ENV={UI_ENV}>
                    <AppHeader />
                    <SideMenu onToggle={setMenuShown} isManufacturer={isManufacturer} isQa={isQa} />
                    <Pages isMenuShown={isMenuShown} isManufacturer={isManufacturer} isQa={isQa} />
                    {!!currentBhome && <CurrentUsers />}
                </AnalProvider>
            </PermissionsProvider>
        </Router>
    );
};

export default Routes;
