import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Grid from 'components/reusables/Grid';
import { isEmpty } from 'lodash-es';
import cx from 'classnames';
import { useToggle } from '@beewise/react-utils';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sequenceColDefs } from './utils';

import './SequencesModal.scss';

const SequencesModal = ({ sequenceMessage, setSequenceMessageId, handleGetMessage }) => {
    const [isLoading, toggleLoading] = useToggle();

    const rows = useMemo(() => sequenceMessage?.payload?.sequences, [sequenceMessage]);
    const getRowId = useCallback(({ data }) => `${data.sequenceId}`, []);

    const handleClose = () => setSequenceMessageId(null);

    const handleUpdate = () => {
        toggleLoading();
        handleGetMessage(sequenceMessage.id, toggleLoading);
    };

    return (
        <Modal
            size="extra-large"
            isOpen={!isEmpty(sequenceMessage)}
            header={
                <div className="sequence-modal-header">
                    {sequenceMessage?.id} Subsequences{' '}
                    <FontAwesomeIcon
                        className={cx('icon-sync', { 'icon-sync-loading': isLoading })}
                        icon={faSync}
                        onClick={handleUpdate}
                    />
                </div>
            }
            onClose={handleClose}
            className="sequences-modal"
        >
            <Grid hideSearch hideDownload rowData={rows} getRowId={getRowId} columnDefs={sequenceColDefs} />
        </Modal>
    );
};

SequencesModal.propTypes = {
    sequenceMessage: PropTypes.shape(),
    setSequenceMessageId: PropTypes.func,
    handleGetMessage: PropTypes.func,
};

export default SequencesModal;
