import React from 'react';
import PropTypes from 'prop-types';
import { Routes, useLocation, Route } from 'react-router-dom';
import cx from 'classnames';
import isSignInViewOrUpView from './utils/isSignInOrUpView';
import PublicRoute from './components/reusables/PublicRoute';
import SignIn from './components/views/SignIn';
import ChangePassword from './components/views/PasswordSetup';
import PrivateRoute from './components/reusables/PrivateRoute';
import Dashboard from './components/views/Config';
import ActionsAndMonitoring from './components/views/ActionsAndMonitoring';
import Frames from './components/views/Frames';
import CamerasCalibrations from './components/views/CamerasCalibrations';
import Statistics from './components/views/Statistics';
import Messages from './components/views/Messages';
import ScriptExecutor from './components/views/ScriptExecutor';
import PowerManagement from './components/views/PowerManagement';
import RfidScanHistory from './components/views/RfidScanHistory';
import ConnectivityMap from './components/views/Monitoring';
import AppAlert from './components/reusables/AppAlert';
import SpecialFeatures from './components/views/SpecialFeatures';
import LayoutActions from './components/views/LayoutActions';
import Errors from './components/views/Errors';
import SwUpdateMonitor from './components/views/SwUpdateMonitor';
import Hives from './components/views/Hives';
import Qa from './components/views/Qa';

const Pages = ({ isMenuShown, isManufacturer, isQa }) => {
    const location = useLocation();
    const isSignInView = isSignInViewOrUpView(location);

    return (
        <section
            id="view"
            className={cx({
                'menu-hidden': !isMenuShown,
                'no-menu': isSignInView,
            })}
        >
            <Routes>
                <Route path="/signin" element={<PublicRoute component={SignIn} />} />
                <Route path="/setup-password" element={<PublicRoute component={ChangePassword} />} />
                <Route path="/" element={<PrivateRoute component={ActionsAndMonitoring} isBhomeRequired />} />
                <Route path="/messages" element={<PrivateRoute component={Messages} />} />
                <Route path="/errors" element={<PrivateRoute component={Errors} isBhomeRequired />} />
                <Route
                    path="/power-management"
                    element={<PrivateRoute component={PowerManagement} isBhomeRequired />}
                />
                <Route
                    path="/cameras-calibrations"
                    element={<PrivateRoute component={CamerasCalibrations} isBhomeRequired />}
                />
                <Route path="/diagnostic-tab" element={<PrivateRoute component={ScriptExecutor} isBhomeRequired />} />
                <Route path="/monitoring" element={<PrivateRoute component={ConnectivityMap} />} />
                {!isManufacturer && (
                    <>
                        <Route path="/frames" element={<PrivateRoute component={Frames} isBhomeRequired />} />
                        <Route path="/config" element={<PrivateRoute component={Dashboard} isBhomeRequired />} />
                        <Route
                            path="/cameras-calibrations"
                            element={<PrivateRoute component={CamerasCalibrations} isBhomeRequired />}
                        />
                        <Route
                            path="/rfid-history"
                            element={<PrivateRoute component={RfidScanHistory} isBhomeRequired />}
                        />
                        <Route path="/statistics" element={<PrivateRoute component={Statistics} />} />
                        <Route path="/layout" element={<PrivateRoute component={LayoutActions} />} />
                        <Route path="/hives" element={<PrivateRoute component={Hives} />} />
                        <Route
                            path="/special-features"
                            element={<PrivateRoute component={SpecialFeatures} isBhomeRequired />}
                        />
                    </>
                )}
                {isQa && <Route path="/qa" element={<PrivateRoute component={Qa} />} />}
                <Route path="/sw-update-monitor" element={<PrivateRoute component={SwUpdateMonitor} />} />
            </Routes>
            <AppAlert />
        </section>
    );
};

Pages.propTypes = {
    isMenuShown: PropTypes.bool,
    isManufacturer: PropTypes.bool,
    isQa: PropTypes.bool,
};

export default Pages;
