import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const EncoderRenderer = ({ data }) => {
    const valueToRender = useMemo(() => {
        const {
            xposencoder = '',
            yposencoder = '',
            zposencoder = '',
            g1posencoder = '',
            g2posencoder = '',
            gaposencoder = '',
            gbposencoder = '',
        } = data;

        return (
            <>
                <div>X: {xposencoder}</div>
                <div>Y: {yposencoder}</div>
                <div>Z: {zposencoder}</div>
                <div>
                    GA: {gaposencoder || g1posencoder} / GB: {gbposencoder || g2posencoder}
                </div>
            </>
        );
    }, [data]);

    return <div className="encoder-renderer">{valueToRender}</div>;
};

EncoderRenderer.propTypes = {
    data: PropTypes.shape(),
};

export default EncoderRenderer;
