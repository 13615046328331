import React, { useState, useCallback, useMemo, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash-es';
import cx from 'classnames';
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import Button from '@beewise/button';
import TextField from '@beewise/text-field';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';

// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });

const Grid = forwardRef(
    (
        {
            rowData,
            onGridReady = noop,
            onRowDataChange = noop,
            children,
            columnDefs,
            gridOptions,
            defaultAddItem,
            handleNewItemSave,
            onCellChange,
            handleItemDelete,
            handleUpdate,
            isAbsoluteHeader = false,
            hideSearch = false,
            hideDownload = false,
            filePrefix = 'messages-export-',
            headerChildren = null,
            className = null,
            searchChildren = null,
            actionChildren = null,
            onSelect,
            handleOnClose,
            onCellClicked,
            selectedBhomeIds,
            handleOnResetView,
            ...rest
        },
        ref
    ) => {
        const [gridApi, setGridApi] = useState(null);

        useEffect(() => {
            onRowDataChange({ gridApi });
        }, [rowData, onRowDataChange, gridApi]);

        const onGridReadyHandler = useCallback(
            params => {
                setGridApi(params.api);
                onGridReady(params);
            },
            [onGridReady]
        );

        const memoizedGridOptions = useMemo(
            () => ({
                ...gridOptions,
                defaultColDef: {
                    flex: 1,
                    sortable: true,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    ...gridOptions?.defaultColDef,
                },
                tooltipShowDelay: 0,
                enableCellTextSelection: true,
            }),
            [gridOptions]
        );

        const handleCsvClick = useCallback(
            () =>
                gridApi.exportDataAsCsv({
                    fileName: `${filePrefix}${Date.now()}`,
                }),
            [filePrefix, gridApi]
        );

        const handleSearchChange = useCallback(value => gridApi?.setGridOption('quickFilterText', value), [gridApi]);

        useEffect(() => {
            if (!gridApi) {
                return;
            }
            gridApi.deselectAll();
            selectedBhomeIds?.forEach(id => {
                const rowNode = gridApi.getRowNode(id);
                if (rowNode) {
                    rowNode.setSelected(true);
                }
            });
        }, [selectedBhomeIds, gridApi]);

        return (
            <div className="grid-wrapper">
                <div
                    className={cx('grid-header', {
                        'grid-header-absolute': isAbsoluteHeader,
                        'grid-header-without-search': hideSearch,
                        'grid-header-with-actions': !!actionChildren,
                    })}
                >
                    {headerChildren}
                    {actionChildren && <div className="grid-header">{actionChildren} </div>}
                    <div className="grid-header">
                        {handleOnClose && (
                            <Button className="grid-csv btn btn-primary" onClick={handleOnClose}>
                                Save View
                            </Button>
                        )}
                        {handleOnResetView && (
                            <Button className="grid-csv btn btn-primary" onClick={handleOnResetView}>
                                Reset View
                            </Button>
                        )}
                        {!hideDownload && (
                            <Button
                                disabled={!gridApi || !gridApi.getDisplayedRowCount()}
                                className="grid-csv btn btn-primary"
                                onClick={handleCsvClick}
                            >
                                Download as CSV
                            </Button>
                        )}
                        {handleUpdate && <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleUpdate} />}
                    </div>
                </div>
                {!hideSearch && (
                    <div className="grid-search">
                        <TextField onChange={handleSearchChange} icon="search" placeholder="Search" size="small" />
                        {searchChildren}
                    </div>
                )}
                <div
                    className={cx('ag-theme-material grid', {
                        [className]: !!className,
                    })}
                >
                    <AgGridReact
                        rowData={rowData}
                        onGridReady={onGridReadyHandler}
                        gridOptions={memoizedGridOptions}
                        rowSelection="multiple"
                        columnDefs={columnDefs}
                        enableCellTextSelection
                        ensureDomOrder
                        onSelectionChanged={onSelect}
                        onCellClicked={onCellClicked}
                        ref={ref}
                        {...rest}
                    >
                        {children}
                    </AgGridReact>
                </div>
            </div>
        );
    }
);

Grid.propTypes = {
    rowData: PropTypes.arrayOf(PropTypes.shape()),
    columnDefs: PropTypes.arrayOf(PropTypes.shape()),
    gridOptions: PropTypes.shape(),
    defaultAddItem: PropTypes.shape(),
    onGridReady: PropTypes.func,
    onRowDataChange: PropTypes.func,
    handleNewItemSave: PropTypes.func,
    handleUpdate: PropTypes.func,
    onCellChange: PropTypes.func,
    handleItemDelete: PropTypes.func,
    children: PropTypes.node,
    searchChildren: PropTypes.node,
    headerChildren: PropTypes.node,
    filePrefix: PropTypes.string,
    className: PropTypes.string,
    hideSearch: PropTypes.bool,
    hideDownload: PropTypes.bool,
    isAbsoluteHeader: PropTypes.bool,
    actionChildren: PropTypes.node,
    onSelect: PropTypes.func,
    handleOnClose: PropTypes.func,
    onCellClicked: PropTypes.func,
    handleOnResetView: PropTypes.func,
    selectedBhomeIds: PropTypes.arrayOf(PropTypes.number),
};

export default Grid;
