import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Grid from 'components/reusables/Grid';
import dayjs from 'dayjs';
import { loading } from '@beewise/react-utils';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';
import DatePicker from 'react-datepicker';
import { isEmpty } from 'lodash-es';
import Toggle from '@beewise/toggle';
import { getCurrentBhome } from 'selectors';
import CustomTooltip from 'components/reusables/CustomTooltip';
import { getFilteredMessages } from './selectors';
import {
    fetchAllBhomesMessages,
    toggleKpiView,
    markMessagesAsFailed,
    fetchMessageById,
    fetchCancelMessage,
} from './actions';
import { FETCH_ALL_BHOMES_MESSAGES } from './actionTypes';
import { getCustomTextMatcher, SOFTWARE_UPDATE_COMMAND } from './utils';
import DecodedRenderer from './components/DecodedRenderer';
import SequenceCellRenderer from './components/Sequences/SequenceCellRenderer';
import SequencesModal from './components/Sequences/SequencesModal';
import StatusRenderer from './components/StatusRenderer';

const Messages = () => {
    const [sequenceMessageId, setSequenceMessageId] = useState(null);
    const [dateRange, setDateRange] = useState([
        dayjs(new Date()).startOf('day').valueOf(),
        dayjs(new Date()).endOf('day').valueOf(),
    ]);
    const [startDate, endDate] = dateRange;
    const dispatch = useDispatch();

    const messages = useSelector(getFilteredMessages);
    const isKpiView = useSelector(state => state.messages.isKpiView);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const sequenceMessage = useMemo(() => {
        if (!sequenceMessageId) {
            return null;
        }
        return messages.find(msg => msg.id === sequenceMessageId);
    }, [messages, sequenceMessageId]);

    const handleUpdate = useCallback(() => {
        if (startDate && endDate) {
            dispatch(
                fetchAllBhomesMessages(
                    isKpiView ? dayjs().subtract(7, 'days').unix() : startDate / 1000,
                    isKpiView ? dayjs().unix() : endDate / 1000,
                    isKpiView ? constants.COMMANDS.SCAN : null
                )
            );
        }
    }, [dispatch, endDate, isKpiView, startDate]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const handleToggleKpi = useCallback(() => dispatch(toggleKpiView()), [dispatch]);

    const handleDateRangeChange = useCallback(range => {
        setDateRange([dayjs(range[0]).valueOf(), !dayjs(range[1]) ? null : dayjs(range[1]).endOf('day').valueOf()]);
    }, []);

    const getRowId = useCallback(({ data }) => `${data.id}`, []);

    const handleSetSequenceMessageId = useCallback(message => setSequenceMessageId(message.id), []);

    const handleCancelSequence = useCallback(
        id => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure you want to cancel the message? This action cannot be undone.')) {
                dispatch(fetchCancelMessage(id));
            }
        },
        [dispatch]
    );

    const columnDefs = useMemo(() => {
        let defs = [
            {
                headerName: 'User',
                field: 'user',
            },
            {
                headerName: 'Command',
                field: 'command',
                valueFormatter: params =>
                    isEmpty(params?.data?.payload?.new_config?.general?.software_updates)
                        ? params?.data?.command
                        : SOFTWARE_UPDATE_COMMAND,
                filterParams: {
                    // customized default params https://www.ag-grid.com/angular-data-grid/filter-text/#text-custom-matcher
                    textMatcher: getCustomTextMatcher,
                },
            },
            {
                headerName: 'Status',
                field: 'status',
                maxWidth: 140,
                tooltipComponent: CustomTooltip,
                cellRenderer: StatusRenderer,
                rendererParams: { onCancelClick: handleCancelSequence },
                headerTooltip: 'Header',
            },
            {
                headerName: 'Sent At',
                field: 'sent_at',
                valueFormatter: params => new Date(params.data.sent_at).toLocaleString(),
                comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
            },
            {
                headerName: 'Updated At',
                field: 'updated_at',
                valueFormatter: params => new Date(params.data.updated_at).toLocaleString(),
                comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
            },
            {
                headerName: 'From',
                field: 'from',
                maxWidth: 120,
            },
            {
                headerName: 'Payload',
                field: 'payload',
                valueGetter: params => JSON.stringify(params.data.payload),
                cellRenderer: DecodedRenderer,
                cellClass: 'no-border',
            },
            {
                headerName: 'Sequence Data',
                field: 'payload',
                cellRenderer: SequenceCellRenderer,
                rendererParams: { onCellClicked: handleSetSequenceMessageId },
                valueFormatter: params => params.data.payload,
                cellClass: 'no-border sequence-cell-renderer',
            },
            {
                headerName: 'ID',
                field: 'id',
            },
            {
                headerName: 'BH ID',
                field: 'bhome_id',
                maxWidth: 110,
            },
        ];

        if (isKpiView) {
            defs = [
                ...defs,
                {
                    headerName: 'KPI',
                    field: 'kpi',
                    maxWidth: 100,
                },
            ];
        }

        return defs;
    }, [handleCancelSequence, handleSetSequenceMessageId, isKpiView]);

    const handleMoveMessage = useCallback(() => {
        if (!currentBhome) {
            return;
        }
        dispatch(markMessagesAsFailed(currentBhome.id, handleUpdate));
    }, [currentBhome, dispatch, handleUpdate]);

    const handleGetMessage = useCallback(
        (id, resolver) => {
            dispatch(fetchMessageById(id, resolver));
        },
        [dispatch]
    );

    return (
        <div className="messages">
            <Toggle
                className="messages-switch checkbox"
                name="messages-kpi"
                label="Show KPI"
                value={isKpiView}
                onChange={handleToggleKpi}
            />
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleUpdate} />
            {!isKpiView && (
                <DatePicker
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    withPortal
                    maxDate={new Date()}
                />
            )}
            {currentBhome && (
                <Button className="btn-primary messages-btn" onClick={handleMoveMessage}>
                    Move processing to failed
                </Button>
            )}
            <Grid rowData={messages} getRowId={getRowId} columnDefs={columnDefs} />
            <SequencesModal
                setSequenceMessageId={setSequenceMessageId}
                sequenceMessage={sequenceMessage}
                handleGetMessage={handleGetMessage}
            />
        </div>
    );
};

export default loading([FETCH_ALL_BHOMES_MESSAGES.default])(Messages);
