import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { useDispatch } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import { usePermission } from '@beewise/react-utils';
import TextField from '@beewise/text-field';
import { JENKINS_BUILD_NAME, getJenkinsTestsOptions, getStationType, CALIBRATE_LOAD_CELL_TEST } from '../../utils';
import { fetchRunJenkinsJob } from '../../actions';

const JenkinsTests = ({ bhome }) => {
    const dispatch = useDispatch();
    const [selectedTests, setSelectedTests] = useState([]);
    const [endModuleSerial, setEndModuleSerial] = useState('');
    const isEMTester = usePermission('isEMTester', 'technician');
    const isGripperTester = usePermission('isGripperTester', 'technician');
    const isFLTester = usePermission('isFLTester', 'technician');
    const isECTester = usePermission('isECTester', 'technician');
    const isStationTester = usePermission('isStationTester', 'technician');

    const isCalibrateLoadCellTest = selectedTests?.includes(CALIBRATE_LOAD_CELL_TEST);

    const handleRunTests = useCallback(() => {
        dispatch(
            fetchRunJenkinsJob(bhome.id, {
                buildName: JENKINS_BUILD_NAME,
                testCases: selectedTests,
                stationType: getStationType(bhome?.id, isStationTester),
                ...(isCalibrateLoadCellTest ? { testData: { end_module_serial: endModuleSerial } } : {}),
            })
        );
    }, [bhome.id, dispatch, endModuleSerial, isCalibrateLoadCellTest, isStationTester, selectedTests]);

    const jenkinsTestOptions = getJenkinsTestsOptions({
        isEMTester,
        isGripperTester,
        isFLTester,
        isECTester,
        isStationTester,
    });

    const isRunDisabled = !selectedTests?.length || (isCalibrateLoadCellTest && endModuleSerial?.length !== 15);

    return (
        <div className="jenkins-scripts-wrapper">
            <div className="jenkins-scripts-item">
                <SelectField
                    value={selectedTests}
                    options={jenkinsTestOptions}
                    onChange={setSelectedTests}
                    placeholder="Choose tests"
                    isMulti
                    size="small"
                />
                {isCalibrateLoadCellTest && (
                    <TextField
                        className="jenkins-scripts-text-field"
                        placeholder="End Module Serial"
                        value={endModuleSerial}
                        onChange={setEndModuleSerial}
                        size="small"
                    />
                )}
                <TechButton enabledInAnyState disabled={isRunDisabled} onClick={handleRunTests} showToManufacturer>
                    Run Tests
                </TechButton>
            </div>
        </div>
    );
};

JenkinsTests.propTypes = {
    bhome: PropTypes.shape(),
};

export default JenkinsTests;
