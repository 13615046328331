import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import isSignInViewOrUpView from 'utils/isSignInOrUpView';
import SideMenu from '@beewise/side-menu';
import { loading } from '@beewise/react-utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentBhome } from 'selectors';
import { fetchUpdateBhomeNote } from 'components/views/Monitoring/actions';
import { FETCH_UPDATE_BHOME_NOTE } from 'components/views/Monitoring/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { getItems } from './utils';

const SideMenuWrapper = ({ onToggle, isManufacturer, isQa }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isSignInView = isSignInViewOrUpView(location);
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const isPermissionFetched = useSelector(state => state.app.isPermissionFetched);
    const [note, setNote] = useState(null);

    useEffect(() => {
        if (bhome?.id) {
            setNote(bhome.note);
        }
    }, [bhome?.id, bhome?.note]);

    const handleNoteChange = useCallback(e => {
        setNote(e.target.value);
    }, []);

    const handleSave = useCallback(() => {
        if (bhome.note !== note) {
            dispatch(fetchUpdateBhomeNote(bhome.id, note));
        }
    }, [bhome?.note, bhome?.id, note, dispatch]);

    const menuItems = getItems({ isManufacturer, isQa });

    if (isSignInView || !isPermissionFetched) {
        return null;
    }

    return (
        <SideMenu items={menuItems} onToggle={onToggle} className={cx({ 'side-menu-no-bhome': !bhome?.id })}>
            {bhome && (
                <div className="side-menu-bhome-note">
                    <div className="side-menu-bhome-note-wrapper">
                        <textarea
                            className="side-menu-bhome-note-textarea"
                            value={note ?? ''}
                            onChange={handleNoteChange}
                        />
                        {bhome?.note_updated_at && (
                            <div className="side-menu-bhome-note-updated-at">
                                {new Date(bhome?.note_updated_at).toLocaleString()}
                            </div>
                        )}
                    </div>
                    <FontAwesomeIcon
                        className={cx('floppy-icon', {
                            'floppy-icon-disabled': bhome.note === note,
                        })}
                        icon={faFloppyDisk}
                        onClick={handleSave}
                    />
                </div>
            )}
        </SideMenu>
    );
};

SideMenuWrapper.propTypes = {
    onToggle: PropTypes.func.isRequired,
    isManufacturer: PropTypes.bool,
    isQa: PropTypes.bool,
};

export default loading(FETCH_UPDATE_BHOME_NOTE.default)(SideMenuWrapper);
