import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Grid from 'components/reusables/Grid';
import { loading } from '@beewise/react-utils';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentBhome } from 'selectors';
import { fetchAllBhomeErrors } from './actions';
import { getFilteredErrors } from './selectors';
import { FETCH_ALL_BHOME_ERRORS, FETCH_UPDATE_BHOME_ERROR } from './actionTypes';
import EncoderRenderer from './components/EncoderRenderer';

const Errors = () => {
    const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(3, 'hour').toDate());
    const [endDate, setEndDate] = useState(dayjs(new Date()).endOf('day').toDate());

    const dispatch = useDispatch();
    const errors = useSelector(getFilteredErrors);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const handleFetchErrorsData = () => {
        if (startDate && endDate) {
            dispatch(
                fetchAllBhomeErrors(
                    currentBhome?.id,
                    dayjs(startDate).valueOf() / 1000,
                    dayjs(endDate).valueOf() / 1000
                )
            );
        }
    };

    useEffect(() => {
        handleFetchErrorsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBhome?.id]);

    const columnDefs = useMemo(
        () => [
            {
                headerName: 'Message id',
                field: 'message_id',
                maxWidth: 100,
            },
            {
                headerName: 'Alert ID',
                field: 'alert_id',
                width: 90,
            },
            {
                headerName: 'Alert Name',
                field: 'alert_name',
                wrapText: true,
                autoHeight: true,
                cellClass: 'alert-name',
                minWidth: 220,
            },
            {
                headerName: 'Alert Type',
                field: 'alert_type',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Created At',
                field: 'created_at',
                valueFormatter: params => new Date(params.data.created_at).toLocaleString(),
                comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
                maxWidth: 200,
            },
            {
                headerName: 'Bee Home State',
                field: 'bhome_status',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Sequence Info',
                field: 'sequence_info',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Posencoders',
                field: 'xposencoder',
                wrapText: true,
                autoHeight: true,
                cellRenderer: EncoderRenderer,
            },
        ],
        []
    );

    const getRowId = useCallback(({ data }) => `${data.id}`, []);

    return (
        <div className="errors">
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleFetchErrorsData} />
            <div className="errors-pickers">
                <span>From:</span>
                <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    withPortal
                    maxDate={endDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    onCalendarClose={handleFetchErrorsData}
                />
                <span>To:</span>
                <DatePicker
                    selected={endDate}
                    onChange={setEndDate}
                    withPortal
                    minDate={startDate}
                    maxDate={new Date()}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="dd/MM/yyyy HH:mm"
                    onCalendarClose={handleFetchErrorsData}
                />
            </div>
            <Grid rowData={errors} getRowId={getRowId} columnDefs={columnDefs} filePrefix="errors-" />
        </div>
    );
};

export default loading([FETCH_ALL_BHOME_ERRORS.default, FETCH_UPDATE_BHOME_ERROR.default])(Errors);
