import { FETCH_ALL_BHOMES_MESSAGES, FETCH_CANCEL_MESSAGE, FETCH_GET_MESSAGE, TOGGLE_KPI_VIEW } from '../actionTypes';

const initialState = {
    messages: [],
    isKpiView: false,
};

const messages = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BHOMES_MESSAGES.success:
            return {
                ...state,
                messages: action.data.messages,
            };
        case FETCH_GET_MESSAGE.success:
        case FETCH_CANCEL_MESSAGE.success:
            return {
                ...state,
                messages: state.messages.map(msg =>
                    msg.id === action.data.message.id || msg.id === action.data.message.pendingId
                        ? {
                              user: msg.user,
                              ...action.data.message,
                          }
                        : msg
                ),
            };
        case TOGGLE_KPI_VIEW:
            return {
                ...state,
                isKpiView: !state.isKpiView,
            };
        default:
            return state;
    }
};

export default messages;
