import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';

const PullOutFrameParams = ({ params, setParams }) => {
    const toggleParam = param => {
        setParams(prevParams => ({
            ...prevParams,
            [param]: !prevParams[param],
        }));
    };

    return (
        <>
            <Checkbox label="Shake" checked={params?.shake ?? false} onChange={() => toggleParam('shake')} />
            <Checkbox
                label="Count Bees"
                checked={params?.count_bees ?? false}
                onChange={() => toggleParam('count_bees')}
            />
            <Checkbox
                label="Only Bee Count"
                checked={params?.only_count_bees ?? false}
                onChange={() => toggleParam('only_count_bees')}
            />
        </>
    );
};

PullOutFrameParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default PullOutFrameParams;
